import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import FancyTextBlockTen from "../../components/programmes/centre-based-lessons/FancyTextBlockTen";
import FeatureFive from "../../components/programmes/centre-based-lessons/FeatureFive";
import Faq from "../../components/programmes/centre-based-lessons/Faq";
import VideoGallery from "../../components/programmes/centre-based-lessons/VideoGallery";
import PricingThree from "../../components/programmes/centre-based-lessons/PricingThree";
import FeaturesEight from "../../components/programmes/centre-based-lessons/FeaturesEight";
import AnalyticsEvent from "../../components/shared/AnalyticsEvent";

const CentreBasedLessons = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Centre Based Lessons
        </title>
      </Helmet>
      
      <Header />
      
      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Centre Based Lessons</h1>
                <p className="sub-heading">
                  Our centres are equipped with digital tools and experienced teachers to help your child fully maximise their learning experience in the digital age.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}

      
      {/*=============================================
          Fancy Text block Ten
      ==============================================  */}
      <div className="fancy-text-block-ten fancy-feature-seven mt-170 md-mt-100 mb-50">
        <div className="container">
          <FancyTextBlockTen />
        </div>
      </div>
      {/* /.fancy-text-block-ten */}


      {/* =============================================
				Fancy Text block Five
			==============================================  */}
      <div className="fancy-text-block-five pt-130 pb-100 md-pt-100 md-pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 ml-auto">
              <div className="title-style-one mb-50 md-mb-30">
                <h2>We teach with digital whiteboards</h2>
                <p className="font-rubik">
                  We believe that digital whiteboards are more superior than traditional marker whiteboards. Here's why.
                </p>
              </div>
              {/*  /.title-style-one */}
            </div>
          </div>

          <div className="wrapper">
            <div className="row justify-content-center">
              <FeatureFive />
            </div>
          </div>
        </div>
      </div>
      {/* /.block-style-five */}

      {/* 	=====================================================
				Fancy Feature Twenty One
			===================================================== */}
      <div className="fancy-feature-twentyOne mt-50 md-mt-50" id="template">
        <div className="container">
          <div
            className="title-style-eight text-center mb-80 md-mb-30"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h2>See it for yourself</h2>
            <p>Catch some snippets on how our centre based lessons are conducted.</p>
          </div>
        </div>

        <div className="lg-container">
          <div className="container">
            <VideoGallery />
          </div>
        </div>
        {/* /.lg-container */}
        </div>
      {/* /.fancy-feature-twentyOne */}

      {/* 
     =============================================
				Fqa Text Block
		============================================== */}
      <div className="fancy-text-block-six overflow-hidden mt-100 mb-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="title-style-five mb-35">
                <h6>Using digital whiteboards</h6>
                <h2>
                  Why go <span>digital</span>?
                </h2>
              </div>
              {/* End title */}
              <Faq />
            </div>

            <div
              className="col-lg-6 col-md-8 m-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="illustration-holder d-flex justify-content-end md-mt-50">
                <img src="images/site/programmes/cbl-digital.webp" alt="digital whiteboards" />
                <img
                  src="images/shape/113.svg"
                  alt="media"
                  className="shapes shape-one"
                />
              </div>
              {/* /.illustration-holder */}
            </div>
          </div>
        </div>
      </div>
      {/* /.why should you choose us */}
      
      {/* 
     =============================================
			Pricing Section Three
		============================================== */}
      <div className="pricing-section-three-inner-page mb-200 md-mb-120">
        <img
          src="images/shape/124.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/125.svg"
          alt="shape"
          className="shapes shape-two"
        />

        <div className="container" id="pricing">
          <div className="title-style-five text-center mb-50 md-mb-40">
            <h6>Our Centre Based Lesson Pricing</h6>
            <div className="row">
              <div className="col-lg-9 m-auto">
                <h2>Contact us to enquire for schedule</h2>
              </div>
            </div>
          </div>

          <div className="pricing-table-area-three">
            <PricingThree />
          </div>
          {/* /.pricing-table-area-three */}


          <div className="fancy-feature-eight mt-50 md-mt-50">
            <div className="container">
              <div className="bg-wrapper title-style-five text-center">
                <h2>Discounts</h2>
                <FeaturesEight />
                <a href="https://wa.me/+6590625285?lang=en" target="_blank" rel="noreferrer"
                className="theme-btn-five mt-50 md-mt-30"
                onClick={() => AnalyticsEvent({
                  category: "WhatsAppEnquire",
                  action: "Click",
                  source: "/centre-based-lessons",
                  label: "centreBasedLessonClickWhatsAppEnquire"
                })}
              >
                <i className="fa fa-whatsapp"></i> Enquire Now
              </a>
              </div>
            </div>
          </div>
          {/* /.fancy-feature-eight */}
        </div>
        {/* ./container */}
      </div>
      {/* /.pricing-section-three */}

      <Footer/>
    </div>
  );
};

export default CentreBasedLessons;
