import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Routes from "./router/Routes.js";
import ScrollToTop from "./components/shared/ScrollToTop.jsx";
import AOS from "aos";
import "aos/dist/aos.css";
import usePageTracking from "./components/shared/usePageTracking.js"

const App = () => {
  usePageTracking();
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <>
      <Helmet titleTemplate="%s - Education made eZ | Woodlands | Choa Chu Kang | Admiralty" defaultTitle="EduZ Tuition - Education made eZ | Woodlands | Choa Chu Kang | Admiralty">
        <meta property="og:site_name" content="EduZ Tuition" />
        <meta
          property="og:url"
          content="https://eduztuition.com"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="EduZ Tuition - Education made eZ | Woodlands | Choa Chu Kang | Admiralty"
        />
        <meta
          name="keywords"
          content="tuition, Education, Exam, Woodlands, Choa Chu Kang, Admiralty, Tutor, Student"
        />
        <meta
          name="description"
          content="EduZ Tuition is a top notch learning centre providing quality education with a team of dedicated staff and a conducive learning environment."
        />

        <meta property="og:image" content="https://www.eduztuition.com/images/site/homepage/preview.png" />
      </Helmet>
      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;
