import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/shared/Header";
import Footer from "../../../components/shared/Footer";
import AnalyticsEvent from "../../../components/shared/AnalyticsEvent";
import JCFeature from "../../../components/programmes/level/LevelFeature";

const JCBlock = [
  {
    icon: "/images/site/icon/level/jc-1.png",
    title: "Junior College 1 (JC1)",
    subTitle: `JC1 is all about being able to manage huge jump in content between secondary and JC, as well as being able to grasp the ‘A’ level syllabus well. Our JC1 program focuses on building a strong foundation for the ‘A’ Level syllabus - giving ample practice for building up the necessary familiarization with test formats and requirements.`,
    dataDelay: "0",
    link: "/programmes/junior-college-1",
    eventId: "jc1",
  },
  {
    icon: "/images/site/icon/level/jc-2.png",
    title: "Junior College 2 (JC2)",
    subTitle: `JC2 is all about tackling the ‘A’ Level with the right set of content, technique and guidance. Our JC2 program aims to hone students’ examination skills like answer precision, question analysis and builds their confidence for the examination. Acing the ‘A’ Level examination is crucial for university enrollment and (oversea/local) scholarship application.`,
    dataDelay: "100",
    link: "/programmes/junior-college-2",
    eventId: "jc2",
  }
];

const JC = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Junior College School Tuition
        </title>
      </Helmet>
      
      <Header />
      
      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent mb-100">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Junior College Tuition</h1>
                <p className="sub-heading">
                  Gearing up students for GCE ‘A’ Level examinations
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      
      <div className="level-text-block-right level-junior-college pt-50 pb-120 md-pt-50 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <div className="title-style-one mb-40 md-mb-20">
                <h2>Junior College</h2>
                <p className="font-rubik">
                Our JC programme help prepare students for their ‘A’ Level examinations. As the JC national exams is less than 2 years 
                away, our teachers understand the importance of pacing the topics and ensuring there is sufficient time to catch up and revise. 
                We also provide support for students who join us mid-way into their studies.
                <br/><br/>
                Our JC subjects include H1 GP, H1/2 Mathematics, H1/2 Physics, H1/2 Chemistry and H1/2 Economics, for both JC1 and JC2 levels.
                </p>
              </div>
            </div>
          </div>

          <div className="wrapper">
            <div className="row justify-content-center">
              <JCFeature levelData={JCBlock} levelCols={6} levelStyle="block-style-level-junior-college" />
              <a href="https://wa.me/+6590625285?lang=en" target="_blank" rel="noreferrer"
                  className="theme-btn-five mt-50 md-mt-30"
                  onClick={() => AnalyticsEvent({
                    category: "WhatsAppEnquire",
                    action: "Click",
                    source: "/programmes/junior-college",
                    label: "jcClickWhatsAppEnquire"
                  })}
                >
                  <i className="fa fa-whatsapp"></i> Enquire Now
                </a>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default JC;
