import React from "react";
import AnalyticsEvent from "../../shared/AnalyticsEvent.jsx";

const blog_202311 = [
  {
    "img": "/images/site/blog/20240915_gep-changes-2024.png",
    "tag": "News",
    "title": "Gifted Education for All? Decoding MOE's Radical Shift in Gifted Education",
    "slug": "gifted-education-for-all-decoding-moe-radical-shift-in-gifted-education",
    "date": "15 September 2024",
    "publishDate": "2023-09-15T12:00:00.000+08:00",
    "content":
    <React.Fragment>
      <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
        <div className="tag">15 Sep 2024</div>
        <h3 className="title">
          Gifted Education for All? Decoding MOE's Radical Shift in Gifted Education
        </h3>
        <p>At Eduz Tuition, we've been closely following the <a href="https://www.channelnewsasia.com/singapore/gifted-education-programme-gep-changed-high-ability-programme-primary-school-4554336" target="_blank">recent changes to Singapore's Gifted Education Programme (GEP)</a> which is set to undergo a major overhaul, 
          affecting how our primary schools nurture high-ability learners. From 2024, the Ministry of Education (MOE) will implement changes that expand opportunities 
          for gifted students while keeping them in their current schools. These updates promise to redefine gifted education in Singapore, potentially impacting up to 
          10% of each cohort. Whether you're a parent of a GEP student, have a child showing promise in specific subjects, or are simply interested in our education 
          system's evolution, these changes could significantly influence your child's learning journey. Let's delve into what this new approach to gifted education 
          means for Singapore's students, parents, and educators.</p>
        <img
          src="/images/site/blog/20240915_gep-changes.png"
          alt="Changes to GEP"
          style={{ maxHeight: '350px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px' }}
        />
        <h4 className="title">Key Changes to the Gifted Education Programme</h4>
        <ol>
          <li><b>Decentralization of Gifted Education.</b> No longer limited to nine primary schools, all primary schools will now be equipped to identify and support high-ability learners.</li>
          <li><b>More Students Included.</b> The programme will now benefit 10% of students, up from 8%, giving more students access to advanced learning opportunities.</li>
          <li><b>Students Remain in Their Current Schools.</b> Selected students will no longer need to transfer to specific GEP schools. This addresses a common concern among parents about maintaining their child's social connections and familiar environment.</li>
          <li><b>Introduction of After-School Enrichment Modules.</b> For students who would benefit from additional challenges, after-school modules will be available at designated nearby schools. These sessions, typically once a week, will focus on fostering curiosity and a love for learning rather than exam preparation.</li>
          <li><b>Implementation Timeline.</b> The current GEP will be phased out starting with the 2024 Primary 1 cohort. Students entering Primary 3 in 2026 and Primary 4 in 2027 will be the first to experience the new programme fully.</li>
        </ol>

        <img
          src="/images/site/blog/20240915_gep-changes-table.png"
          alt="Changes to GEP"
          style={{ maxHeight: '550px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px' }}
        />

        <h4 className="title">A New Selection Process: More Holistic Approach</h4>
        <p>The revamped selection process aims to be more inclusive and flexible:</p>
        <ol>
          <li><b>Primary 3 Screening Test:</b> The initial assessment of English and Mathematics capabilities will be retained.</li>
          <li><b>Elimination of Second-Stage Test:</b> The current second stage of GEP selection will be discontinued, reducing stress on students.</li>
          <li><b>Ongoing Identification:</b> Students can be identified for high-ability programmes at multiple points between Primary 4 and Primary 6, allowing for recognition of late bloomers.</li>
          <li><b>Holistic School-Based Assessment:</b> Teachers will play a crucial role in identifying suitable students through daily observations, classwork performance, and demonstrations of exceptional skills.</li>
          <li><b>Subject-Specific Selection:</b> Students can be chosen for high-ability programmes in one or more subjects based on their individual strengths.</li>
        </ol>

        <h4 className="title">Common Concerns</h4>
        <ol>
          <li><b>Quality of Education Across Schools.</b> Many parents worry whether their neighbourhood school can provide the same quality of gifted education as the current GEP schools. MOE emphasized that resources, including experienced teachers, will be shared across all schools. They're committed to ensuring that high-quality education is accessible regardless of the school a child attends. However, it's important to recognize that this will be a gradual process, and parents should stay engaged with their schools to understand how these changes are being implemented.</li>
          <li><b>Student Stress Levels.</b> A key aim of the new system is to reduce stress by allowing students to develop at their own pace and in their areas of strength. It's less about excelling in every subject and more about nurturing individual talents. This shift might actually help alleviate some of the pressure students feel to be "all-rounders." However, it's crucial for parents to adjust their expectations accordingly and support their children's individual growth journeys.</li>
          <li><b>Social and Emotional Development.</b> Keeping gifted students in their current schools aims to support their social-emotional growth. This addresses a common concern about GEP students being isolated from their peers. However, some parents worry whether their children will still have opportunities to interact with like-minded peers. The after-school enrichment modules are designed to provide these opportunities while maintaining a balance with regular school life.</li>
          <li><b>Identification of Gifted Students.</b> The new approach to identifying gifted students is more holistic, which some parents find reassuring while others worry it might be less objective. It's important to understand that this method aims to recognize a wider range of talents and abilities, potentially benefiting students who might have been overlooked in the past. Parents should familiarize themselves with the new identification process and maintain open communication with teachers about their child's progress.</li>
          <li><b>Preparation for GEP.</b> With the changes, the focus is shifting away from test preparation towards nurturing natural talents and curiosity. This might be a significant mindset shift for many parents accustomed to the idea of "preparing" for GEP selection. While it's natural to want to support your child's learning, excessive tutoring or "hot housing" is discouraged as it may not align with the new approach to identifying and nurturing gifted students.</li>
          <li><b>Long-term Academic Competitiveness.</b> Some parents express concern about whether these changes might affect Singapore's educational standards and global competitiveness. It's important to note that the goal of these changes is not to lower standards, but to nurture talents more effectively and inclusively. The focus on developing critical thinking, creativity, and subject-specific strengths aims to prepare students for the challenges of the future workforce.</li>
          <li><b>Teacher Preparedness.</b> The new system places significant responsibility on teachers to identify and nurture high-ability learners. While this raises concerns about teacher workload and preparedness, MOE is planning additional support, including training and resources. Parents can support this transition by maintaining respectful and collaborative relationships with teachers, recognizing the complexity of their role in this new system.</li>
        </ol>

        <h4 className="title">Potential Impact on Students</h4>
        <ol>
          <li><b>More Inclusive Approach:</b> A larger percentage of students will have access to advanced learning opportunities.</li>
          <li><b>Reduced Stress:</b> Eliminating school transfers and reducing focus on selection tests may decrease stress for students and parents.</li>
          <li><b>Tailored Development:</b> The new system allows for more personalized approaches, catering to students who excel in specific subjects.Making General Paper compulsory ensures all JC students build critical language and thinking skills needed for university.</li>
          <li><b>Stay Informed:</b> Keep up-to-date with MOE announcements and your school's implementation plans.</li>
        </ol>

        <h4 className="title">Preparing Your Child for the New System</h4>
        <ol>
          <li><b>Open Communication:</b> Discuss these changes with your child, addressing any questions or concerns they might have.</li>
          <li><b>Encourage Exploration:</b> Support your child in exploring various subjects and activities to discover their passions and strengths.</li>
          <li><b>Foster Independent Learning:</b> Help your child develop self-directed learning skills, which will be valuable in this more flexible system.</li>
          <li><b>Stay Informed:</b> Keep up-to-date with MOE announcements and your school's implementation plans.</li>
        </ol>

        <p>The revamped GEP represents a significant shift towards a more inclusive and flexible approach to nurturing high-ability learners in Singapore. While challenges in implementation may arise, the focus on individual strengths, reduced stress, and broader access to advanced learning opportunities are positive steps forward.</p>
        <p>At Eduz Tuition, we see great potential in these changes to create a more supportive environment for all learners. As we navigate this transition together, let's focus on fostering a love for learning and supporting our children's unique journeys. By working collaboratively with schools and embracing these changes, we can help ensure the success of this new approach to gifted education in Singapore.</p>
        <p>Remember, every child has unique gifts and talents. Our role as parents and educators is to help them discover and nurture these abilities, preparing them not just for academic success, but for a fulfilling life ahead.</p>
        <h4 className="title">Source:</h4>
        <ul>
          <li><a href="https://www.channelnewsasia.com/singapore/gifted-education-programme-gep-changes-4554486" target="_blank">Channel News Asia - FAQ: What you need to know about Singapore’s revamped Gifted Education Programme</a></li>
          <li><a href="https://www.straitstimes.com/singapore/politics/ndr-2024-gifted-education-programme-to-be-updated-all-primary-schools-to-stretch-high-ability-pupils" target="_blank">The Straits Times - NDR 2024: Gifted Education Programme to be updated; all primary schools to stretch high-ability pupils</a></li>
          <li><a href="https://www.straitstimes.com/singapore/politics/ndr-2024-key-changes-to-the-education-landscape" target="_blank">The Straits Times - NDR 2024: Extra support for schools among key changes to the education landscape</a></li>
          <li><a href="https://www.moe.gov.sg/education-in-sg/our-programmes/gifted-education" target="_blank">Ministry of Education (MOE) - Gifted Education Programme</a></li>
        </ul>

        <p></p>
        <div style={{ textAlign: 'center' }}>
          <p>Keen to learn more about Eduz Tuition?</p>
          <a href="https://wa.me/+6590625285?lang=en"
          target="_blank"
          rel="noreferrer"
          className="theme-btn-five"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
          onClick={() => AnalyticsEvent({
            category: "WhatsAppEnquire",
            action: "Click",
            source: "/blog/gifted-education-for-all-decoding-moe-radical-shift-in-gifted-education",
            label: "blogClickWhatsAppEnquire"
          })}
        >
        <i className="fa fa-whatsapp"></i> Chat with us now
      </a>
      </div>
      <p></p>
      </div>
    </React.Fragment>
  },
  {
    "img": "/images/site/blog/20231111_our-thoughts-on-the-upcoming-changes-to-a-level-curriculum.png",
    "tag": "News",
    "title": "Our thoughts on the upcoming changes to A level curriculum",
    "slug": "our-thoughts-on-the-upcoming-changes-to-a-level-curriculum",
    "date": "11 November 2023",
    "publishDate": "2023-11-11T12:00:00.000+08:00",
    "content":
    <React.Fragment>
      <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
        <div className="tag">11 Nov 2023</div>
        <h3 className="title">
          Our thoughts on the upcoming changes to A level curriculum
        </h3>
        <p>We have earlier highlighted in <a href="/blog/changes-to-a-level-curriculum" target="_blank">our blog post in March</a> on the upcoming changes announced by MOE to the A-Level curriculum. Here's a quick summary to recap.</p>
        <p>The Ministry of Education (MOE) announced significant changes to the GCE A-Level examination scoring system which will take effect from 2026 onwards. This move aims to reduce the over-emphasis on academics and grades, while providing students with more flexibility to explore interests beyond scoring well.</p>
        <p>The key changes are:</p>
        <h4 className="title">Removal of Mid-Year Exams</h4>
        <ul>
          <li>Starting 2024, mid-year exams will be progressively removed for all Junior College (JC) students. This eliminates a major high-stakes examination that previously contributed significantly to a student's overall grade.</li>
          <li>The time savings from not preparing for mid-years can be used for more meaningful learning through diverse experiences. Students can immerse in passion projects, community service and holistic development.</li>
          <li>However, schools cannot replace mid-years with more frequent school-based weighted assessments. There will be a cap of only one weighted assessment per subject per term.</li>
        </ul>

        <h4 className="title">Revised Scoring for Project Work</h4>
        <ul>
          <li>From 2024 onwards, Project Work will be graded as pass/fail instead of scoring. Students must pass Project Work to qualify for university admission.</li>
          <li>This reduces the academic pressure on Project Work, which is meant to develop creativity, teamwork and other skills. Students can explore their interests without chasing grades.</li>
          <li>The Project Work curriculum remains rigorous as students still need to demonstrate relevant skills and competencies. But the stakes are lowered by removing grades.</li>
        </ul>

        <h4 className="title">Fourth Subject only counts if it improves score</h4>
        <ul>
          <li>Currently, the best 3 H2 and 1 H1 subject scores are counted towards the University Admission Score, which is out of 90.</li>
          <li>From 2026, the fourth content-based subject will only count towards the overall score if it pulls up the student's score.</li>
          <li>This gives students the flexibility to take an additional subject they are interested in but may not excel at. Previously, students avoided such subjects as it may pull down their score.</li>
          <li>With this change, students can explore more subjects and the maximum score will be reduced to 70 points.</li>
          <li>Students can take 3 H2 and 1 H1 subject or 4 H2 subjects. Their weakest H2 subject will not automatically count as an H1.</li>
        </ul>

        <h4 className="title">General Paper Made Compulsory</h4>
        <ul>
          <li>From 2024, all JC students must take General Paper rather than the Knowledge & Inquiry subject. General Paper will develop core competencies in language, writing and current affairs.</li>
          <li>Knowledge & Inquiry will still be offered for students who prefer learning about the nature and construction of knowledge.</li>
          <li>Making General Paper compulsory ensures all JC students build critical language and thinking skills needed for university.</li>
        </ul>

        <h4 className="title">More Mixing Across Academic Tiers with Full Subject-Based Banding</h4>
        <ul>
          <li>Full subject-based banding will be implemented in 120 secondary schools from 2024. Students will be grouped into 3 tiers based on PSLE scores only for posting purposes.</li>
          <li>Subjects will be offered across different tiers. Students will take subjects based on their strengths, not their posted tier. There will be greater diversity within form classes.</li>
          <li>This creates flexibility for students to customize subject combinations based on interests and abilities. Their posted tier does not limit subject choices.</li>
          <li>Specialised schools are exempted as they already offer niche programmes.</li>
        </ul>

        <img
          src="/images/site/blog/20230303_changes-curriculum.png"
          alt="Changes to curriculum"
          style={{ maxHeight: '550px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px' }}
        />

        <p>These changes are part of MOE's continuous efforts to create a more holistic education system. By reducing the focus on grades and creating space for passions to grow, students can enjoy learning for its own sake. As a trusted tuition provider, EduZ Tuition recommends the following:</p>

        <h4 className="title">Recommendations for Parents:</h4>
        <ul>
          <li>Understand your child's unique interests and strengths. Guide them towards subjects they are intrinsically motivated to learn.</li>
          <li>Focus on their learning journey and progress rather than grades. Help them set meaningful goals.</li>
          <li>Allow time for non-academic pursuits. Support their holistic development through CCAs and other learning opportunities.</li>
          <li>Work closely with teachers and tutors to customize your child's learning experience based on their needs and aspirations.</li>
        </ul>

        <h4 className="title">How EduZ Tuition Provides Support:</h4>
        <p>As a leading tuition centre, EduZ Tuition has experience helping students through major education policy changes. Here is how we can support your child during this transition:</p>
        <ul>
          <li>Our tutors include ex-MOE teachers, graduate tutors and experienced educators. They understand the objectives behind these changes and can provide guidance to students.</li>
          <li>We offer tuition across academic tiers for secondary school and JC subjects. Our small class sizes allow personalized attention to each student's strengths and interests.</li>
          <li>Beyond covering the academic curriculum, we focus on igniting curiosity and a love for learning. Students develop critical thinking and problem solving skills.</li>
          <li>Our tutors and consultants are available to advise on subject combinations, balancing academic load, and pathways to maximize opportunities.</li>
          <li>We partner with parents and teachers to align goals and customize learning for each child. Our priority is nurturing inquisitive young minds.</li>
        </ul>

        <p>As MOE continues enhancing the education system to meet Singapore's future needs, EduZ Tuition stands ready to help every student thrive in this new environment. We aim to be a trusted partner for families seeking quality tuition and guidance during this important transition.</p>

        <p></p>
        <div style={{ textAlign: 'center' }}>
          <p>Keen to learn more about Eduz Tuition?</p>
          <a href="https://wa.me/+6590625285?lang=en"
          target="_blank"
          rel="noreferrer"
          className="theme-btn-five"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
          onClick={() => AnalyticsEvent({
            category: "WhatsAppEnquire",
            action: "Click",
            source: "/blog/our-thoughts-on-the-upcoming-changes-to-a-level-curriculum",
            label: "blogClickWhatsAppEnquire"
          })}
        >
        <i className="fa fa-whatsapp"></i> Chat with us now
      </a>
      </div>
      <p></p>
      </div>
    </React.Fragment>
  },
]


export default blog_202311