import { React, useEffect } from "react";

import Homepage from "../views/homepage/Homepage.jsx";
import Contact from "../views/contact/Contact.jsx";
import AboutUs from "../views/about/AboutUs.jsx";
import Team from "../views/about/Team.jsx";
import Testimonials from "../views/about/Testimonials.jsx";
import Programmes from "../views/programmes/Programmes.jsx";
import CentreBasedLessons from "../views/programmes/CentreBasedLessons.jsx";
import OnlineLessons from "../views/programmes/OnlineLessons.jsx";
import ArtClasses from "../views/programmes/ArtClasses.jsx";
import Primary from "../views/programmes/level/Primary.jsx";
import Secondary from "../views/programmes/level/Secondary.jsx";
import JC from "../views/programmes/level/JC.jsx";
import LevelSubject from "../views/programmes/level/Level.jsx";
import SubjectLevel from "../views/programmes/subject/Subject.jsx";
import Pricing from "../views/pricing/Pricing.jsx";

import BlogList from "../views/blog/BlogList.jsx"
import BlogDetail from "../views/blog/BlogDetail.jsx"

import Privacy from "../views/Misc/Privacy.jsx";
import Terms from "../views/Misc/Terms.jsx";
import Trial22 from "../views/Misc/Trial.jsx";

import FileDownload from "../components/shared/FileDownload.jsx";

// Not Found Page
import NotFound from "../views/NotFound.jsx";

// Route Specific
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import ScrollTopBehaviour from "../components/shared/ScrollTopBehaviour.jsx";

export const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);
};

const Routes = () => {
  return (
    <Router>
      <ScrollTopBehaviour />
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/contact-us" component={Contact} />
        <Route exact path="/our-branches" component={Contact} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/team" component={Team} />
        <Route exact path="/our-team" component={Team} />
        <Route exact path="/testimonials" component={Testimonials} />
        <Route exact path="/our-results" component={Testimonials} />
        <Route exact path="/programmes" component={Programmes} />
        <Route exact path="/our-courses" component={Programmes} />
        <Route exact path="/centre-based-lessons" component={CentreBasedLessons} />
        <Route exact path="/online-lessons" component={OnlineLessons} />
        <Route exact path="/art-classes" component={ArtClasses} />
        
        <Route exact path="/programmes/primary" component={Primary} />
        <Route exact path="/programmes/secondary" component={Secondary} />
        <Route exact path="/programmes/junior-college" component={JC} />

        <Route exact path="/programmes/primary-1" component={() => <LevelSubject level={`primary-1`} />} />
        <Route exact path="/programmes/primary-2" component={() => <LevelSubject level={`primary-2`} />} />
        <Route exact path="/programmes/primary-3" component={() => <LevelSubject level={`primary-3`} />} />
        <Route exact path="/programmes/primary-4" component={() => <LevelSubject level={`primary-4`} />} />
        <Route exact path="/programmes/primary-5" component={() => <LevelSubject level={`primary-5`} />} />
        <Route exact path="/programmes/primary-6" component={() => <LevelSubject level={`primary-6`} />} />
        <Route exact path="/programmes/secondary-1" component={() => <LevelSubject level={`secondary-1`} />} />
        <Route exact path="/programmes/secondary-2" component={() => <LevelSubject level={`secondary-2`} />} />
        <Route exact path="/programmes/secondary-3" component={() => <LevelSubject level={`secondary-3`} />} />
        <Route exact path="/programmes/secondary-4" component={() => <LevelSubject level={`secondary-4`} />} />
        <Route exact path="/programmes/junior-college-1" component={() => <LevelSubject level={`junior-college-1`} />} />
        <Route exact path="/programmes/junior-college-2" component={() => <LevelSubject level={`junior-college-2`} />} />

        <Route exact path="/programmes/english" component={() => <SubjectLevel subject={'english'} />} />
        <Route exact path="/programmes/chinese" component={() => <SubjectLevel subject={'chinese'} />} />
        <Route exact path="/programmes/math" component={() => <SubjectLevel subject={'math'} />} />
        <Route exact path="/programmes/science" component={() => <SubjectLevel subject={'science'} />} />
        <Route exact path="/programmes/econs" component={() => <SubjectLevel subject={'econs'} />} />

        <Route exact path="/pricing" component={Pricing} />
        
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/freetrialclass22" component={Trial22} />

        <Route exact path="/blog" component={BlogList} />
        <Route path="/blog/:slug" component={BlogDetail} />

        <Route exact path="/curriculum-package/:level" component={() => <FileDownload type={'CurriculumDownload'} medium={"whatsapp"} />} />
        <Route exact path="/newsletter/email/:quarter" component={() => <FileDownload type={'NewsletterDownload'} medium={"emailNewsletter"} />} />
        <Route exact path="/newsletter/whatsapp/:quarter" component={() => <FileDownload type={'NewsletterDownload'} medium={"whatsAppNewsletter"} />} />
        
        {/* NotFound Route */}
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
