import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import PricingMain from "../../components/pricing/Pricing";
import FeaturesMain from "../../components/pricing/Features";

const Pricing = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Pricing
        </title>
      </Helmet>
      
      <Header />
      
      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Pricing</h1>
                <p className="sub-heading">
                Our pricing structure is designed to provide maximum educational value with no hidden costs. We don't charge registration or material fees, allowing you to focus solely on your child's academic growth.
                </p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}
      {/* 
     =============================================
			Pricing Section Three
		============================================== */}
      <div className="pricing-section-three-inner-page mb-200 md-mb-120">
        <img
          src="images/shape/124.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/125.svg"
          alt="shape"
          className="shapes shape-two"
        />

        <div className="container" id="pricing">
          <div className="title-style-five text-center mb-50 md-mb-40">
            <h6>Our Centre Based Lesson Pricing</h6>
            <div className="row">
              <div className="col-lg-9 m-auto">
                <h2>Contact us to enquire for schedule</h2>
              </div>
            </div>
          </div>

          <div className="pricing-table-area-three">
            <PricingMain />
          </div>
          {/* /.pricing-table-area-three */}


          <div className="fancy-feature-eight mt-50 md-mt-50">
            <div className="container">
              <div className="bg-wrapper title-style-five text-center">
                <h2>Discounts</h2>
                <FeaturesMain />
              </div>
            </div>
          </div>
          {/* /.fancy-feature-eight */}
          
        </div>
        {/* ./container */}
      </div>
      {/* /.pricing-section-three */}

      <Footer/>
    </div>
  );
};

export default Pricing;
