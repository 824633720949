import React from "react";
import AnalyticsEvent from "../shared/AnalyticsEvent";

const Newsletter = () => {
  return (
    <div className="row">
      <div
        className="col-lg-6 order-lg-last"
        data-aos="fade-left"
        data-aos-duration="1200"
      >
        <div className="text-wrapper pl-3">
          <div className="title-style-three mb-35 md-mb-20">
            <h6>EduZ Tuition Newsletter Q2 2025</h6>
            <h2> 
              <span>
              Unleash Your Academic Superpowers{" "}
                <img src="images/shape/line-shape-7.svg" alt="shape" />
              </span>
              
            </h2>
          </div>
          {/*  /.title-style-two */}
          <p>
          Buckle up for an academic adventure with EduZ Tuition's Q2 2025 newsletter! 🚀 Unleash your inner study superhero with game-changing planning tools, mind-expanding study techniques, and jaw-dropping knowledge bombs. Peek behind the scenes with <b>Teacher Frances</b> and discover the secrets to academic mastery. Packed with insider tips, fascinating facts, and strategies that actually work, this isn't just a newsletter—it's your ultimate weapon for crushing learning goals and transforming education from boring to brilliant! Are you ready to level up? 💡🔥
          </p>
          <p className="mt-30">
          <a href="/docs/newsletter/EduZTuition-Q2-2025-Newsletter.pdf"
                target="_blank"
                rel="noreferrer"
                className="theme-btn-five"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                onClick={() => AnalyticsEvent({
                  category: "NewsletterDownload",
                  action: "Click",
                  source: "/",
                  label: "homepageNewsletterButton-q2-2025"
                })}
              >
                <i className="fa fa-file-pdf-o"></i> Download
              </a>
            </p>
        </div>
      </div>
      <div className="col-lg-6 order-lg-first">
        <div className="img-gallery">
        <a href="/docs/newsletter/EduZTuition-Q2-2025-Newsletter.pdf"
            target="_blank"
            rel="noreferrer"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
            onClick={() => AnalyticsEvent({
              category: "NewsletterDownload",
              action: "Click",
              source: "/",
              label: "homepageNewsletterImage-q2-2025"
            })}
          >
            <img
              src="images/site/homepage/newsletter/q2-2025-newsletter-1.webp"
              alt="media"
              data-aos="fade-right"
              data-aos-duration="1200"
              style={{border: "2px black solid"}}
            />
          </a>
          <img
            src="images/shape/49.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/54.svg"
            alt="shape"
            className="shapes shape-three"
          />
        </div>
        {/* /.img-gallery */}
      </div>
    </div>
  );
};

export default Newsletter;
