import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/shared/Header";
import Footer from "../../../components/shared/Footer";
import AnalyticsEvent from "../../../components/shared/AnalyticsEvent";
import SecondaryFeature from "../../../components/programmes/level/LevelFeature";

const SecondaryBlock = [
  {
    icon: "/images/site/icon/level/sec-1.png",
    title: "Secondary 1",
    subTitle: `Our Sec 1 program focuses on managing the academic and social transition to secondary schools. Topics of higher complexity are introduced and a more diverse demand, including more schooling subjects, is required. Support is provided to help with this transition.`,
    dataDelay: "0",
    link: "/programmes/secondary-1",
    eventId: "sec1",
  },
  {
    icon: "/images/site/icon/level/sec-2.png",
    title: "Secondary 2",
    subTitle: `Our Sec 2 program focuses on building upon the Sec 1 content while preparing for upper secondary content. Complex questions and topics are introduced to prepare students for subject-choosing at the end of the year.`,
    dataDelay: "100",
    link: "/programmes/secondary-2",
    eventId: "sec2",
  },
  {
    icon: "/images/site/icon/level/sec-3.png",
    title: "Secondary 3",
    subTitle: `Our Sec 3 program focuses on diving more deeply into various concepts in preparation for their GCE 'N' or 'O' level examination. Schools teach these advanced subjects at a much faster pace. Tutors aid students by providing summaries and revisiting the concepts from another angle.`,
    dataDelay: "300",
    link: "/programmes/secondary-3",
    eventId: "sec3",
  },
  {
    icon: "/images/site/icon/level/sec-4.png",
    title: "Secondary 4",
    subTitle: `Our Sec 4 program focuses on preparing students for their GCE 'N' or 'O' level examinations. Academic revision, question exposure and examination skills refinement are of priority in this year. Ample resources are also provided for self-directed study further revision.`,
    dataDelay: "300",
    link: "/programmes/secondary-4",
    eventId: "sec4",
  },
];

const Secondary = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Secondary School Tuition
        </title>
      </Helmet>
      
      <Header />
      
      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent mb-100">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Secondary School Tuition</h1>
                <p className="sub-heading">
                  Gearing up students for GCE 'N' & 'O' Level examinations
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      
      <div className="level-text-block-right level-secondary pt-50 pb-120 md-pt-50 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <div className="title-style-one mb-40 md-mb-20">
                <h2>Secondary School</h2>
                <p className="font-rubik">
                Our secondary school program help prepare students for their <strong>GCE 'N' and/or ‘O’ Level examinations</strong>. As 
                the national exam is only a short sprint away, our tutors will provide the relevant <strong> personalized aid </strong> 
                to students depending on where they are in their secondary school journey.
                <br/><br/>
                Our lower sec subjects include English, Math, Chinese and Science while upper secondary subjects include English, Chinese, 
                A Math, E Math, Pure Chemistry, Pure Physics and Combined Chem/Phy. 
                </p>
              </div>
            </div>
          </div>

          <div className="wrapper">
            <div className="row justify-content-center">
              <SecondaryFeature levelData={SecondaryBlock} levelCols={6} levelStyle="block-style-level-secondary" />
              <a href="https://wa.me/+6590625285?lang=en" target="_blank" rel="noreferrer"
                  className="theme-btn-five mt-50 md-mt-30"
                  onClick={() => AnalyticsEvent({
                    category: "WhatsAppEnquire",
                    action: "Click",
                    source: "/programmes/junior-college",
                    label: "jcClickWhatsAppEnquire"
                  })}
                >
                  <i className="fa fa-whatsapp"></i> Enquire Now
                </a>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Secondary;
